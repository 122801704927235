import { get, put, Delete, post } from "../axios";

// 用户管理-班主任列表
export const teacherUserTeacherPage = (params) =>
  get(`/web/teacher/user/teacherPage`, params);

// 用户管理-班主任下拉
export const teacherList = (params) =>
  get(`/web/teacher/user/teacherList`, params);

// 用户管理-班主任新增
export const addTeacher = (params) =>
  post(`/web/teacher/user/addTeacher`, params);

// 用户管理-班主任删除
export const teacherDelete = (params) =>
  Delete("/web/teacher/user/teacherDelete/" + params, "");

//用户管理-班主任查看
export const teacherView = (params) =>
  get("/web/teacher/user/view/" + params, "");

//用户管理-班主任修改
export const teacherUpdateTeacher = (params) =>
  put("/web/teacher/user/updateTeacher", params);

//用户管理-班主任重置密码
export const teacherResetPassword = (params) =>
  put("/web/teacher/user/resetPassword", params);

//用户管理-班主任启用禁用
export const teacherUpdateState = (params) =>
  put("/web/teacher/user/updateState", params);
