<template>
  <div class="main-content">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.fullName"
                  placeholder="请输入班主任姓名"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.phone"
                  placeholder="请输入联系电话"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-select
                  v-model="searchData.state"
                  clearable
                  placeholder="状态"
                >
                  <el-option
                    v-for="(item, index) in OStatus"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <el-button
              size="medium"
              type="primary"
              @click="handleSearch"
              style="margin-bottom: 10px"
              class="marginLeft10"
              >搜索</el-button
            >
            <el-button
              size="medium"
              type="info"
              @click="handleReset"
              style="margin-bottom: 10px"
              class="marginLeft10 marginRight10"
              >重置</el-button
            >
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleCreate"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </template>

      <template slot="state" slot-scope="{ row }">
        <div>
          {{
            row.state == EStatus.Enable
              ? MStatus[row.state]
              : row.state == EStatus.Disabled
              ? MStatus[row.state]
              : ""
          }}
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
          style="color: #ff001f !important"
          >删除</el-button
        >
        <el-button type="text" size="mini" @click="resetPassword(row)"
          >重置密码</el-button
        >
        <el-button type="text" size="mini" @click="getState(row)">
          {{
            row.state == EStatus.Enable
              ? MStatus[EStatus.Disabled]
              : row.state == EStatus.Disabled
              ? MStatus[EStatus.Enable]
              : ""
          }}</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      v-if="dialogVisible"
      :title="`${form.id ? '编辑' : '新建'}`"
      :visible.sync="dialogVisible"
      width="650px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <avue-form ref="form" v-model="form" :option="formOption">
        <template slot="password">
          <div>初始密码123456</div>
        </template>

        <template slot="menuForm">
          <el-button @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            :loading="showLoadingForm"
            @click="submit(form)"
            >确认</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
    <!-- 重置密码 -->
    <el-dialog
      v-if="visiblePassWord"
      title="重设密码"
      :visible.sync="visiblePassWord"
      width="650px"
      :before-close="recert"
      :close-on-click-modal="false"
      ><el-form
        ref="formPassWord"
        :model="formPassWord"
        label-position="right"
        label-width="180px"
        :rules="rules"
      >
        <el-form-item label="手机号（登录账号）:" prop="password">
          <el-input
            v-model="formPassWord.phone"
            style="width: 300px"
            maxlength="20"
            size="small"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="登录密码:" prop="passWordNew">
          <span>{{ formPassWord.password }}</span>
          <span class="marginLeft10"
            ><el-button type="text" @click="getCopy">复制</el-button></span
          >
        </el-form-item>
        <div style="display: flex;justify-content: center;">
          <el-button size="small" @click="recert">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  teacherUserTeacherPage,
  teacherList,
  addTeacher,
  teacherDelete,
  teacherView,
  teacherUpdateTeacher,
  teacherResetPassword,
  teacherUpdateState,
} from "@/api/user/teacher";
import dayjs from "dayjs";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import qs from "qs";
import { MStatus, OStatus, EStatus } from "@/enums/beCurrent/EBeCurrent";
import { mobilePhoneRule } from "@/utils/formRules/index";
export default {
  name: "index",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      MStatus: MStatus,
      OStatus: OStatus,
      EStatus: EStatus,
      searchData: {},
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        selection: false,
        reserveSelection: true,
        selectionFixed: true,
        tip: false,
        column: [
          {
            label: "id",
            prop: "id",
          },
          {
            label: "班主任姓名",
            prop: "fullName",
          },
          {
            label: "联系电话",
            prop: "phone",
          },
          {
            label: "状态",
            prop: "state",
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "200",
        row: true,
        column: [
          {
            label: "班主任姓名",
            prop: "fullName",
            maxlength: 10,
            span: 20,
            row: true,
            placeholder: "输入班主任姓名",
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },

          {
            label: "班主任电话（登录账号）",
            prop: "phone",
            // type: "text",
            maxlength: 11,
            span: 20,
            row: true,
            placeholder: "输入联系电话",
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
              ...mobilePhoneRule,
            ],
          },
          {
            label: "登录密码",
            prop: "password",
            maxlength: 200,
            span: 20,
            row: true,
            display: false,
          },
        ],
      },
      cityList: [], //城市选择列表
      visiblePassWord: false, //密码弹窗

      formPassWord: {},
    };
  },
  created() {
    // this.getCity();
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        ...this.searchData,
      };
      teacherUserTeacherPage({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        state: "",
        name: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 添加
    handleCreate() {
      const password = this.findObject(this.formOption.column, "password");
      password.display = true;
      this.form = {};
      this.dialogVisible = true;
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    //编辑
    async handleEdit(row) {
      const password = this.findObject(this.formOption.column, "password");
      password.display = false;

      this.form.id = row.id;
      this.dialogVisible = true;
      this.form = { ...row, avatarUrl: row.avatarUrl ? [row.avatarUrl] : [] };
    },

    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        teacherDelete(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          }
        });
      });
    },

    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          console.log(form, "formformform");
          // return
          if (form.id) {
            teacherUpdateTeacher({
              ...form,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                  this.showLoadingForm = false;
                } else {
                  // this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  done();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          } else {
            addTeacher({
              ...form,
              password: "123456",
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                  this.showLoadingForm = false;
                } else {
                  // this.$message.error(res.msg);
                  this.showLoadingForm = false;
                  done();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          }
          // this.form.type = 1;
          // done()
        }
      });
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },

    submitPassWord(form) {
      if (this.loadingSubmit) {
        return;
      }
      this.loadingSubmit = true;
      this.$refs.formPassWord.validate((valid) => {
        if (valid) {
          storeResetPassword({
            password: this.formPassWord.password,
            id: this.id,
          })
            .then((res) => {
              // 获取数据成功后的其他操作
              if (res.code == 200) {
                this.visiblePassWord = false;
                this.loadingSubmit = false;
                this.$message.success("修改成功");
              } else {
                this.$message.error(res.msg);
                this.loadingSubmit = false;
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
              this.loadingSubmit = false;
            });
        }
      });
    },
    recert() {
      this.visiblePassWord = false;
    },

    //修改启用禁用
    getState(row) {
      teacherUpdateState({
        id: row.id,
        state: row.state == 1 ? 2 : 1,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("修改成功");
          this.onLoad();
        }
      });
    },
    resetPassword(row) {
      this.$confirm("确定重置密码吗？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        teacherResetPassword({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.formPassWord = {
              ...res.data,
              phone: row.phone,
            };
            this.visiblePassWord = true;
          }
        });
      });
    },
    //复制
    getCopy() {
      const tempInput = document.createElement("textarea");
      tempInput.value = this.formPassWord.password; // 设置要复制的文本
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$message.success("复制成功");
    },
  },
};
</script>

<style scoped lang="scss">
.table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table_item {
  margin-bottom: 10px;
  width: 30%;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  .table_label {
    width: 100px;
  }
}
.table_input {
  margin-right: 10px;
}
.table_add {
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
}
</style>
